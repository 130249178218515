/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef } from 'react';
import {
    array, bool, func, object, string,
} from 'prop-types';
import {
    Chip, Paper, MenuItem, makeStyles, useMediaQuery,
} from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'block',
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '10px',
        fontFamily: 'Lato',
        '@media only screen and (max-width: 600.5px) ': {
            fontSize: '18px',
            lineHeight: '32px',
            marginBottom: '8px',
        },
    },
    chipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0,
        boxShadow: 'none',
        margin: '0px',
        backgroundColor: 'transparent',
        alignItems: 'center',
        '@media only screen and (max-width: 600.5px) ': {
            flexWrap: 'nowrap',
            overflowX: 'scroll',
            paddingBottom: '10px',
            transition: 'all 0.5s ease-in-out',
            '&::-webkit-scrollbar': {  /* Hide scrollbar for Chrome, Safari and Opera */
                display: 'none',
            },
            '-ms-overflow-style': 'none',  /* IE and Edge */
            scrollbarWidth: 'none',  /* Firefox */
        },
        '& li': {
            margin: '4px 2px',
            '& [class*="smooth-scroll"]': {
                transition: 'all 0.5s ease-in-out',
            },
        },
        '& .MuiChip-label': {
            fontSize: '16px',
            padding: '8px 16px',
            lineHeight: '20px',
            fontFamily: 'Lato',
        },
    },
    selectListChip: {
        position: 'relative',
    },
    selectedChipRoot: {
        '& .MuiChip-deletable': {
            backgroundColor: theme.palette.cms?.white || '#ffffff',
            border: '1px solid #DBD5D2',
            '& svg': {
                fill: theme.palette.cms?.black || '#2f2f2f',
                width: '18px',
            },
        },
    },
    chip: ({ styled }) => ({
        height: '37px',
        textTransform: 'capitalize',
        background: styled?.pills_background_color?.color || '#e0e0e0',
        color: styled?.pills_font_color?.color || ' #2f2f2f',
        '&:hover,&:focus': {
            background: styled?.pills_background_color?.color || '#e0e0e0',
            color: styled?.pills_font_color?.color || '#2f2f2f',
        },
    }),
    selectedChip: ({ styled }) => ({
        textTransform: 'capitalize',
        background: styled?.pills_active_color?.color || '#2f2f2f',
        color: styled?.pills_active_font_color?.color || '#ffffff',
        '&:hover, &:focus, &:active': {
            background: styled?.pills_active_color?.color || '#2f2f2f',
            color: styled?.pills_active_font_color?.color || '#ffffff',
        },
    }),
    mainContainer: {
        marginBottom: '28px',
        '@media only screen and (max-width: 600.5px) ': {
            marginBottom: '10px',
        },
    },
    subHeader: {
        fontSize: '18px',
        lineHeight: '26px',
        display: 'flex',
        margin: '16px 0px 8px 0px',
        '@media only screen and (max-width: 600.5px) ': {
            margin: '2px 0px 5px 0px',
        },
    },
    optionContainer: {
        '@media only screen and (min-width: 600px) ': {
            position: 'absolute',
            width: '100%',
            top: '87px',
            maxHeight: '250px',
        },
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        boxShadow: '0px 4px 8px 0px #0000001A',
        maxHeight: '200px',
        overflowY: 'scroll',
        textTransform: 'capitalize',
        zIndex: 999,
        '& li': {
            padding: '11px 16px',
            fontSize: '18px',
            lineHeight: '26px',
            '&:hover': {
                backgroundColor: '#E9E9E9',
            },
        },
    },
    selectedOptions: {
        backgroundColor: '#E9E9E9',
    },
    selectedIcon: ({ styled }) => ({
        color: styled?.pills_active_font_color?.color || '#ffffff',
        '&:hover': {
            color: styled?.pills_active_font_color?.color || '#ffffff',
        },
    }),
    unselectedIcon: ({ styled }) => ({
        color: styled?.pills_font_color?.color || ' #2f2f2f',
        '&:hover': {
            color: styled?.pills_font_color?.color || ' #2f2f2f',
        },
    }),
}));
const PerfectGiftFastModalOptions = ({
    label,
    filterOptionsData,
    handleClick,
    selected,
    selectedOption,
    selectInput,
    selectMenu,
    handleOptionSelect,
    handleDelete,
    selectedChips,
    loginFilterOptionData,
    isModalEdit,
    open,
    multiSelectedInterest,
    dataLabel,
    styled,
}) => {
    const classes = useStyles({ styled });
    const seed = useUIDSeed();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const selectChipClass = selectedOption?.name ? classes.selectedIcon : classes.unselectedIcon;
    const firstProfileName = `${loginFilterOptionData?.[0]?.recipFirstName?.toLowerCase()} ${loginFilterOptionData?.[0]?.recipLastName?.toLowerCase()}`;

    useEffect(() => {
        if (loginFilterOptionData?.length && !isModalEdit && !selectedOption?.relationshipName) {
            handleOptionSelect(loginFilterOptionData?.[0]);
        }
    }, [loginFilterOptionData]);

    const occasionRef = useRef([]);
    const recipientRef = useRef([]);
    const scrollToSmoothly = (ref, value) => {
        if (ref?.current?.[value] !== undefined && isMobile) {
            ref.current?.[value]?.classList?.add('smooth-scroll');
            ref?.current?.[value]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToSmoothly(occasionRef, selected);
        }, 500);
        setTimeout(() => {
            scrollToSmoothly(recipientRef, selected);
        }, 100);
    }, [open, isModalEdit, filterOptionsData, isMobile]);

    return (
        <div className={`${classes.mainContainer} ${selectMenu ? classes.selectListChip : ''}`} id="modalOptions">
            {label && <strong className={classes.header}>{label}</strong>}
            <Paper
                component="ul"
                className={classes.chipRoot}
                arial-label="hidden"
                tabIndex="-1"
            >
                {selectInput && (
                    <div>
                        <Chip
                            label={selectedOption?.name?.toLowerCase() || firstProfileName ||  'Select Recipient'}
                            onDelete={handleDelete}
                            onClick={handleDelete}
                            className={`${classes.chip} ${ selectedOption?.name ? classes.selectedChip : '' }`}
                            deleteIcon={selectMenu ? <ExpandLess className={selectChipClass} /> : <ExpandMore className={selectChipClass} />}
                        />
                    </div>
                )}
                {filterOptionsData?.map((item) => (
                    <li key={seed(item)}>
                        <Chip
                            ref={(ref) => {
                                if (dataLabel === 'occasion') {
                                    occasionRef.current[item?.occasion_title] = ref;
                                } else if (dataLabel === 'recipients') {
                                    recipientRef.current[item?.recipient_label] = ref;
                                }
                            }}
                            label={item?.interest_title || item?.recipient_label || item?.occasion_title}
                            className={`${classes.chip} ${(selected === (item?.recipient_label || item?.occasion_title))
                                        || (multiSelectedInterest?.some((selectedItem) => selectedItem?.interest_title === item?.interest_title)) ? classes.selectedChip : ''}`}
                            onClick={() => handleClick(item)}
                        />
                    </li>
                ))}
            </Paper>
            {selectMenu && (
                <div className={classes.optionContainer}>
                    {loginFilterOptionData.map((option) => {
                        const relationshipName = `${option?.recipFirstName} ${option?.recipLastName}`;
                        return (
                            <MenuItem
                                key={option.option_name}
                                onClick={() => handleOptionSelect(option)}
                                className={selectedOption?.name === relationshipName ? classes.selectedOptions : ''}
                            >
                                {`${option?.recipFirstName?.toLowerCase()} ${option?.recipLastName?.toLowerCase()}`}
                            </MenuItem>
                        );
                    })}
                </div>
            )}
            {selectedChips?.length > 0 && (
                <div>
                    <span className={classes.subHeader}>Selected:</span>
                    <Paper
                        component="ul"
                        className={`${classes.chipRoot} ${classes.selectedChipRoot}`}
                        aria-label="hidden"
                        tabIndex="-1"
                    >
                        {selectedChips.map((chip) => (
                            <li key={seed(chip)}>
                                <Chip
                                    label={chip?.interest_title}
                                    onDelete={handleDelete(chip)}
                                    className={classes.chip}
                                    deleteIcon={<CloseIcon />}
                                />
                            </li>
                        ))}
                    </Paper>
                </div>
            )}
        </div>
    );
};

PerfectGiftFastModalOptions.propTypes = {
    label: string,
    filterOptionsData: array,
    handleClick: func,
    selected: string,
    selectedOption: object,
    selectInput: bool,
    handleOptionSelect: func,
    selectMenu: bool,
    handleDelete: func,
    selectedChips: array,
    loginFilterOptionData: array,
    isModalEdit: bool,
    open: bool,
    dataLabel: string,
    multiSelectedInterest: array,
    styled: object,
};
PerfectGiftFastModalOptions.defaultProps = {
    loginFilterOptionData: [],
    selectedChips: [],
    filterOptionsData: [],
    selected: '',
    selectedOption: {},
    selectInput: false,
    isModalEdit: false,
    dataLabel: '',
    open: false,
    label: '',
    handleOptionSelect: () => {},
    selectMenu: false,
    handleClick: () => {},
    handleDelete: () => {},
    multiSelectedInterest: [],
    styled: {},
};

export default PerfectGiftFastModalOptions;
