/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
    array,
    bool, func, object, string,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../../../gql';
import findRecipientRank from '../../../../../../gql/queries/findRecipientRank';
import { getBrand } from '../../../../../../pages/Account/state/ducks/Common/Common-Selectors';
import useGetAccessTokenSafely from '../../../../../../gql/hooks/useGetAccessTokenSafely';
import { getProfileInfo } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import PerfectGiftFastModalOptions from './PerfectGiftFastModalOptions';

const PerfectGiftFastLoginRecipient = ({
    handleClick,
    filterOptionsData,
    selected,
    selectedOption,
    handleOptionSelect,
    selectMenu,
    label,
    handleSelectProfile,
    isModalEdit,
    dataLabel,
    open,
    styled,
}) => {
    const jwt = useGetAccessTokenSafely();
    const profile = useSelector(getProfileInfo);
    const brand = useSelector(getBrand);
    const queryVariables = {
        brand: brand.domain || 'harryanddavid',
        environment: GRAPHQL_ENV,
        emailId: profile.email,
        model: 'SCORE',
    };
    const { loading, error, data } = useQuery(findRecipientRank(), {
        variables: queryVariables,
        context: {
            api: 'personalizedContent',
            jwt,
        },
        skip: !jwt,
    });
    if (loading) return <></>;
    if (error || !data || data?.recipientRank?.recipientList?.length <= 0) {
        mbpLogger.logError({
            function: 'FindRecipientRank',
            appName: process.env.npm_package_name,
            jsError: error,
            component: 'PerfectGiftFastLoginRecipient.js',
            message: 'Failed to fetch data for recipient',
        });
        return (
            <PerfectGiftFastModalOptions
                label={label}
                filterOptionsData={filterOptionsData}
                handleClick={handleClick}
                selected={selected}
                dataLabel={dataLabel}
                open={open}
                styled={styled}
            />
        );
    }

    return (
        <PerfectGiftFastModalOptions
            label={label}
            filterOptionsData={filterOptionsData}
            loginFilterOptionData={data?.recipientRank?.recipientList}
            handleClick={handleClick}
            selected={selected}
            selectedOption={selectedOption}
            selectInput
            handleOptionSelect={handleOptionSelect}
            selectMenu={selectMenu}
            handleDelete={handleSelectProfile}
            isModalEdit={isModalEdit}
            dataLabel={dataLabel}
            open={open}
            styled={styled}
        />
    );
};
PerfectGiftFastLoginRecipient.propTypes = {
    selectedOption: object,
    handleOptionSelect: func.isRequired,
    selectMenu: bool.isRequired,
    handleClick: func.isRequired,
    filterOptionsData: array,
    selected: string,
    label: string.isRequired,
    handleSelectProfile: func.isRequired,
    isModalEdit: bool,
    dataLabel: string,
    open: bool.isRequired,
    styled: object,
};

PerfectGiftFastLoginRecipient.defaultProps = {
    selectedOption: {},
    filterOptionsData: [],
    selected: '',
    isModalEdit: false,
    dataLabel: '',
    styled: {},
};
export default PerfectGiftFastLoginRecipient;
