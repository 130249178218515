/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { object, bool, func } from 'prop-types';
import { Chip, useMediaQuery, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    clearValidatedZipcode, resetProductFilterZipcodeValidity, setProductFilterOccasion, validateProductFilterZipcode,
} from '../../../../../../../state/ducks/App/App-Actions';
import PerfectGiftModalOptions from './PerfectGiftFastModalOptions';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import PerfectGiftFastModal from './PerfectGiftFastModal';
import {
    country, getIsProductFilterZipcodeValid, getUserSubmittedProductFilterZipcode, getValidatedZipcode,
} from '../../../../../../../state/ducks/App/App-Selectors';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import PerfectGiftFastModalZipField from './PerfectGiftFastModalZipField';

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'block',
        textAlign: 'center',
        padding: '28px 0px 8px',
        margin: '10px auto 30px',
        maxWidth: '1400px',
        width: '100%',
        '@media only screen and (max-width: 767px) ': {
            margin: '0px 15px 15px',
            width: 'auto',
        },
    },
    mainContainerwithGrid: {
        margin: 'auto',
        '@media only screen and (max-width: 767px) ': {
            margin: '0px 15px',
        },
    },
    header: {
        fontSize: '36px',
        lineHeight: '47px',
        display: 'block',
        fontFamily: 'playfairdisplaybold',
    },
    subHeader: {
        display: 'block',
        fontSize: '18px',
        lineHeight: '26px',
        margin: '12px 0px 4px',
        fontFamily: 'Lato',
    },
    occasionContainer: {
        display: 'flex',
        maxWidth: '1024px',
        margin: '0 auto',
        justifyContent: 'center',
        '&>div': {
            '@media only screen and (max-width: 1024px) ': {
                width: '95%',
            },
        },
        '& ul': {
            justifyContent: 'center',
            '@media only screen and (max-width: 1024px) ': {
                flexWrap: 'nowrap',
                overflowX: 'scroll',
                paddingBottom: '10px',
                justifyContent: 'flex-start',
            },
        },
    },
    moreOptions: ({ styled }) => ({
        margin: '3px 2px',
        height: '37px',
        padding: ' 8px 4px',
        fontSize: '16px',
        fontFamily: 'Lato',
        lineHeight: '20px',
        background: styled.pills_background_color?.color,
        color: styled.pills_font_color?.color,
        '&:hover,&:focus': {
            background: styled.pills_background_color?.color,
            color: styled.pills_font_color?.color,
        },
    }),
    perfectGiftImage: {
        marginBottom: '4px',
    },
}));

const PerfectGiftFastContainer = ({
    blockData, open, isModalEdit, closeEditModal,
}) => {
    const styled = blockData?.styling || {};
    const { whichGiftFinderVariant } = useFlags();
    const ldVariant = whichGiftFinderVariant?.variant;
    const classes = useStyles({ styled });
    const isZipFirstStep  = blockData?.zip_as_first_step;
    const occasion = blockData?.filter_options?.filter((item) => item?.occasions);
    const zipCodeField = blockData?.filter_options?.filter((item) => item?.zip_code) || [];
    const occasionsValue = occasion?.[0]?.occasions?.occasions || [];
    const occasionList = occasionsValue?.slice(0, 7);
    const occasionSelect = isZipFirstStep ? [] : occasionList;
    const [occasionData, setOccasionData] = useState(occasionSelect);
    const [openModal, setOpenModal] = useState(false);
    const [hideZipModal, setHideZipModal] = useState(false);

    const getZipCode = useSelector(getUserSubmittedProductFilterZipcode);
    const [zip, setZip] = useState(getZipCode);
    const validatedZip = useSelector(getValidatedZipcode);
    const zipCodeIsValid = useSelector(getIsProductFilterZipcodeValid);
    const countryName = useSelector(country);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const dispatch = useDispatch();
    const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);
    const eventCategory = blockData?.tracking?.event_category;
    const addedTagging = (eventLabel, eventType) => (
        dispatch(trackEvent({
            eventCategory: isModalEdit ? `${eventCategory}-Edit` : eventCategory || '',
            eventAction: blockData?.tracking?.event_action || '',
            eventLabel: eventType ? `${eventType}:${eventLabel}` : eventLabel || '',
        }))
    );
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

    useEffect(() => {
        if (typeof window !== 'undefined' && !window.wuPfEventFired) {
            dispatch(trackEvent({
                eventCategory: blockData?.a_b_testing?.tracking_event_category || eventCategory,
                eventAction: blockData?.a_b_testing?.tracking_event_action || blockData?.tracking?.event_action,
                eventLabel: blockData?.a_b_testing?.tracking_event_label ||  ldVariant,
            }));
            window.wuPfEventFired = true;
        }
        return () => {
            window.wuPfEventFired = false;
        };
    }, []);

    useEffect(() => {
        if (!zipCodeIsValid && zipCodeIsValid !== null && zip) {
            addedTagging('Invalid Zip Code', 'Zip');
        }
    }, [zipCodeIsValid]);

    useEffect(() => {
        setOpenModal(open);
    }, [open]);
    const setSelectedOccasion = (item) => {
        const currentOccasion = occasionsValue.filter((occ) => occ.occasion_title === item?.occasion_title);
        dispatch(setProductFilterOccasion(currentOccasion?.[0]?.occasion_title, currentOccasion?.[0]?.occasion_code, currentOccasion?.[0]?.occasion_category_id));
        setOpenModal(true);
        const label = openModal ? 'Occasion' : 'Occasion Start';
        addedTagging(currentOccasion?.[0]?.occasion_title, label);
    };
    const handleMoreOptions = () => {
        setOccasionData(occasionsValue);
    };
    const convertZipCode = (zipcode, preState) => {
        // handle zipcode conversion for canda
        if (preState.length < zipcode.length
            && zipcode.length >= 6
            && isCandaZip(zipcode)) {
            const zipSplit = zipcode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            return zipSplit.join('');
        }
        return zipcode;
    };
    const handleZip = (zipCode) => {
        const countryNames = countryName.toLowerCase();
        if ((zipCode.length >= 5 && country !== 'canada') || (zipCode.length >= 7 && countryNames === 'canada')) {
            // send zip to redux store, then check if valid
            dispatch(validateProductFilterZipcode(zipCode));
        } else if (zipCode.length < 5 && validatedZip !== '') {
            dispatch(clearValidatedZipcode());
        }
        if (zipCode.length < 5 && zipCodeIsValid !== null) {
            dispatch(resetProductFilterZipcodeValidity());
        }
    };
    const handleZipChange = (name) => (event) => {
        const textBoxVal = event.target.value.trim();
        if (name === 'zip' && textBoxVal.length <= 7) {
            const zipCode = convertZipCode(textBoxVal, zip);
            // handle update for local zip input
            setZip(zipCode?.trim());
            handleZip(zipCode);
        }
    };
    let dataObj = blockData;
    if (isZipFirstStep) {
        // Find the index of the "zip_code" object
        const data = [...dataObj.filter_options];
        const ziCodesIndex = data.findIndex((obj) => Object.prototype.hasOwnProperty.call(obj, 'zip_code'));
        const ziCodesObj = data?.splice(ziCodesIndex, 1)[0];
        data.unshift(ziCodesObj);
        dataObj = {
            ...blockData,
            filter_options: data,
        };
    }

    return (
        <>
            {!isModalEdit && (
                <div
                    className={`${classes.mainContainer} ${ffHasHomepageGridStructure && classes.mainContainerwithGrid}`}
                    style={{ backgroundColor: blockData?.styling?.background_color?.color }}
                >
                    <ResponsiveImage
                        className={classes.perfectGiftImage}
                        path={blockData?.header?.icon?.url}
                        alt="perfect_gift_icon"
                        dimensions={{ width: '41px', height: '36px' }}
                    />
                    <div className={classes.header}>{blockData?.header?.heading}</div>
                    {isZipFirstStep ? '' : (
                        <span className={classes.subHeader}>
                            {blockData?.header?.sub_heading}
                        </span>
                    )}
                    { isZipFirstStep
                        ? (
                            <PerfectGiftFastModalZipField
                                label={zipCodeField[0]?.zip_code?.title}
                                zip={zip}
                                handleChange={handleZipChange}
                                validatedZip={validatedZip}
                                getZipCode={getZipCode}
                                addedTagging={addedTagging}
                                zipCodeIsValid={zipCodeIsValid}
                                setHideZipModal={setHideZipModal}
                                hideZipModal={hideZipModal}
                                openDialog={setOpenModal}
                                isModalEdit={isModalEdit}
                                isZipFirstStep={isZipFirstStep}
                            />
                        ) : (
                            <div className={classes.occasionContainer}>
                                <PerfectGiftModalOptions
                                    filterOptionsData={isDesktop ? occasionData : occasionsValue}
                                    handleClick={setSelectedOccasion}
                                    selected={occasion?.code}
                                    styled={styled}
                                />
                                {occasionData?.length <= 7 && isDesktop && (
                                    <Chip
                                        label="More options"
                                        className={classes.moreOptions}
                                        onClick={() => handleMoreOptions()}
                                    />
                                )}
                            </div>
                        )}
                </div>
            )}

            <PerfectGiftFastModal
                open={openModal}
                handleChange={handleZipChange}
                zip={zip}
                setOpen={setOpenModal}
                closeEditModal={closeEditModal}
                giftFinderFieldStyle={classes}
                blockData={dataObj}
                isMobile={isMobile}
                setSelectedOccasion={setSelectedOccasion}
                isModalEdit={isModalEdit}
                getZipCode={getZipCode}
                validatedZip={validatedZip}
                addedTagging={addedTagging}
                zipCodeIsValid={zipCodeIsValid}
                styled={styled}
            />
        </>
    );
};

PerfectGiftFastContainer.defaultProps = {
    open: false,
    isModalEdit: false,
    closeEditModal: func,
};
PerfectGiftFastContainer.propTypes = {
    open: bool,
    blockData: object.isRequired,
    isModalEdit: bool,
    closeEditModal: () => {},
};

export default PerfectGiftFastContainer;
