/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
    array, bool, func, object, string,
} from 'prop-types';
import {
    Dialog, DialogContent, IconButton, Button, makeStyles, DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import { setProductFilterProfile } from '../../../../../../../state/ducks/App/App-Actions';
import { getProductFilterOccasion, getProductFilterProfile } from '../../../../../../../state/ducks/App/App-Selectors';
import { getIsAuthenticatedStatus } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import PerfectGiftFastLoginRecipient from './PerfectGiftFastLoginRecipient';
import PerfectGiftFastModalOptions from './PerfectGiftFastModalOptions';
import PerfectGiftFastModalZipField from './PerfectGiftFastModalZipField';
import { LeftToRightTransition } from '../../../../GraphqlProductPage/Partials/GraphqlPDPCalendar/Partials/Helper/helper';
import { emitCustomTrackingEvent } from '../../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    modalBox: ({ styled }) => ({
        maxWidth: '768px',
        width: '100%',
        borderRadius: 'inherit',
        height: '100%',
        backgroundColor: styled?.background_color?.color || '#FAF9F0',
        '@media only screen and (max-width: 600.5px) ': {
            margin: '0px',
            maxHeight: '100%',
        },
        '& .MuiDialogContent-root': {
            padding: '0px 28px',
            '@media only screen and (max-width: 600.5px) ': {
                padding: '0px 16px',
            },
        },
        '& .MuiDialogTitle-root': {
            padding: '16px',
            '@media only screen and (max-width: 600.5px) ': {
                padding: '14px',
            },
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '& .MuiIconButton-root:hover ': {
            backgroundColor: 'transparent',
        },
    }),
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontSize: '32px',
        lineHeight: '42px',
        alignItems: 'center',
        fontFamily: 'playfairdisplaybold',
    },
    closeButtonContainer: {
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    closeBtn: {
        padding: '0px',
    },
    bottomButtonMainContainer: {
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        boxShadow: '0px -12px 17px 2px rgba(0, 0, 0, 0.04)',
        '& .MuiButton-root.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.26)',
        },
    },
    bottomButtonContainer: {
        display: 'flex',
        padding: '16px 11px 16px 8px',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        '@media only screen and (max-width: 600.5px) ': {
            padding: '20px 11px 20px 8px',
        },
    },
    buttonContainer: {
        '& .MuiButton-root.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.26)',
        },
    },
    suggestionsButton: ({ styled }) => ({
        maxWidth: '320px',
        display: 'flex',
        margin: '0px',
        cursor: 'pointer',
        backgroundColor: styled?.cta_background_color?.color || '#6E3290 ',
        textTransform: 'capitalize',
        color: styled?.cta_font_color?.color || '#ffffff',
        padding: '15px 0px',
        fontSize: '18px',
        lineHeight: '26px',
        fontFamily: 'Lato',
        width: '100%',
        '&:hover': {
            backgroundColor: styled?.cta_background_color?.color || '#6E3290 ',
            color: styled?.styling?.cta_font_color?.color || '#ffffff',
        },
        '@media only screen and (max-width: 600.5px) ': {
            maxWidth: '100%',
        },
    }),
    cancelButton: {
        textDecoration: 'underline',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '0px 33px 0px 25px',
        cursor: 'pointer',
        fontFamily: 'Lato',
        '@media only screen and (min-width: 600.5px) ': {
            padding: '0px 79px 0px 0px',
        },
    },
}));

const PerfectGiftFastModal = ({
    open,
    setOpen,
    handleChange,
    onSubmitCheck,
    zip,
    blockData,
    isMobile,
    setSelectedOccasion,
    isModalEdit,
    getZipCode,
    validatedZip,
    closeEditModal,
    addedTagging,
    zipCodeIsValid,
}) => {
    const styled = blockData?.styling || {};
    const classes = useStyles({ styled });
    const location = useLocation();
    const urlParams = QueryString.parse(location.search?.replace('?', ''));
    const selectedChoice = urlParams?.choice || [];
    const interestValue = blockData?.filter_options?.filter((item) => item?.interests);
    const interestBlock = interestValue?.[0]?.interests?.interests || [];
    const selectInterest = interestBlock?.filter((item) => selectedChoice.includes(item.interest_category_id));
    const [interestData, setInterestData] = useState(interestBlock);
    const [selectedInterest, setSelectedInterest] = useState(selectInterest);
    const [selectMenu, setSelectMenu] = useState(false);
    const [hideZipModal, setHideZipModal] = useState(isModalEdit);
    const occasion = useSelector(getProductFilterOccasion);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const selectedProfileInfo = useSelector(getProductFilterProfile);
    const dispatch = useDispatch();
    const history = useHistory();
    const addInterestChip = (item) => {
        setSelectedInterest((prevSelectedChips) => [...prevSelectedChips, item]);
        addedTagging(item?.interest_title, 'Interest Selected');
    };

    const toggleInterestChip = (item) => {
        if (selectedInterest.includes(item)) {
            // Item already selected, so remove it
            setSelectedInterest((prevSelectedChips) => prevSelectedChips.filter((chip) => chip !== item));
            addedTagging(item?.interest_title, 'Interest Deselected');
        } else {
            // Item not selected, so add it
            setSelectedInterest((prevSelectedChips) => [...prevSelectedChips, item]);
            addedTagging(item?.interest_title, 'Interest Selected');
        }
    };
    useEffect(() => {
        if (!getZipCode) {
            setHideZipModal(false);
        }
    }, [getZipCode]);

    const removeInterestChip = (chipToDelete) => () => {
        setSelectedInterest((prevSelectedChips) => prevSelectedChips.filter((chip) => chip !== chipToDelete));
        addedTagging(chipToDelete?.interest_title, 'Interest Deselected');
    };

    const setFilterProfile = (profile) => {
        dispatch(setProductFilterProfile('', profile?.recipient_code, profile?.recipient_label));
        setSelectMenu(false);
        addedTagging(profile?.recipient_label, 'Recipient');
    };
    const handleProfileSelect = (option) => {
        const relationshipName = `${option?.recipFirstName} ${option?.recipLastName}`;
        dispatch(setProductFilterProfile(relationshipName, '', ''));
        setSelectMenu(false);
        addedTagging(relationshipName, 'Recipient');
    };
    const handleSelectProfile = () => {
        setSelectMenu(!selectMenu);
    };

    const emitCustomClickstreamEvent = () => {
        const { relationshipName: selectedRelationShip = '' } = selectedProfileInfo;
        const filteredOcassionName = occasion?.name ? occasion.name.replace(/'/g, '').toLowerCase() : '';
        dispatch(emitCustomTrackingEvent({
            action: 'customer-input',
            relationship: selectedRelationShip?.toLowerCase(),
            occasionCode: filteredOcassionName,
            page: { type: 'home' },
            eventSubType: 'customer-input-find-gift-fast',
        }));
    };

    const handleSuggestion = () => {
        const queryParams = selectedInterest.map((choice) => `choice[]=${choice.interest_category_id}`).join('&');
        let queryString = '';
        if (occasion?.occasionCode) {
            queryString += `?occasion=${occasion?.occasionCode}`;
        }
        if (selectedProfileInfo?.name || selectedProfileInfo?.relationshipName) {
            if (queryString) {
                queryString += `&for=${selectedProfileInfo?.name || selectedProfileInfo?.relationshipName}`;
            } else {
                queryString += `?for=${selectedProfileInfo?.name || selectedProfileInfo?.relationshipName}`;
            }
        }
        if (selectedInterest?.length > 0) {
            if (queryString) {
                queryString += `&${queryParams}`;
            } else {
                queryString += `?${queryParams}`;
            }
        }
        emitCustomClickstreamEvent();
        const path = '/perfect-gifts';
        history.push({
            pathname: path,
            search: queryString,
        });
        setOpen(false);
        if (isModalEdit) {
            closeEditModal(false);
        }
        addedTagging(blockData?.header?.cta?.title);
    };
    const closeModal = () => {
        setOpen(false);
        if (isModalEdit) {
            closeEditModal(false);
        }
        addedTagging('Closed', 'Modal');
    };
    const isMultiSelectInterestEnable = useSelector(getFeatureFlag('fpg-multi-select-interests'));

    useEffect(() => {
        const updatedInterests = interestBlock.filter((interest) => !selectedInterest.some((selectedInterests) => selectedInterests.interest_title === interest.interest_title));
        setInterestData(updatedInterests);
    }, [selectedInterest?.length]);
    const hasZipCodeObject = blockData?.filter_options?.filter((obj) => 'zip_code' in obj).length > 0;
    const renderFields = (block) => {
        const blockKey = Object.keys(block)?.[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'occasions':
                return (
                    <PerfectGiftFastModalOptions
                        label={blockObj?.title}
                        filterOptionsData={blockObj?.occasions}
                        handleClick={setSelectedOccasion}
                        selected={occasion?.name}
                        dataLabel="occasion"
                        open={open}
                        isModalEdit={isModalEdit}
                        styled={styled}
                    />
                );
            case 'recipients':
                return isAuthenticated ? (
                    <PerfectGiftFastLoginRecipient
                        label={blockObj?.title}
                        filterOptionsData={blockObj?.recipients}
                        handleClick={setFilterProfile}
                        selected={selectedProfileInfo?.relationshipName}
                        selectedOption={selectedProfileInfo}
                        handleOptionSelect={handleProfileSelect}
                        selectMenu={selectMenu}
                        handleSelectProfile={handleSelectProfile}
                        isModalEdit={isModalEdit}
                        dataLabel="recipients"
                        open={open}
                        styled={styled}
                    />
                ) : (
                    <PerfectGiftFastModalOptions
                        label={blockObj?.title}
                        filterOptionsData={blockObj?.recipients}
                        handleClick={setFilterProfile}
                        selected={selectedProfileInfo?.relationshipName}
                        dataLabel="recipients"
                        isModalEdit={isModalEdit}
                        open={open}
                        styled={styled}
                    />
                );
            case 'zip_code':
                return (
                    <PerfectGiftFastModalZipField
                        label={blockObj?.title}
                        zip={zip}
                        handleChange={handleChange}
                        onSubmitCheck={onSubmitCheck}
                        selectedOption={selectedProfileInfo}
                        validatedZip={validatedZip}
                        getZipCode={getZipCode}
                        setHideZipModal={setHideZipModal}
                        hideZipModal={hideZipModal}
                        addedTagging={addedTagging}
                        zipCodeIsValid={zipCodeIsValid}
                    />
                );
            case 'interests':
                return (
                    !isMobile && isMultiSelectInterestEnable ? (
                        <PerfectGiftFastModalOptions
                            label={blockObj?.title}
                            filterOptionsData={blockObj?.interests}
                            handleClick={toggleInterestChip}
                            multiSelectedInterest={selectedInterest}
                            styled={styled}
                        />
                    ) : (
                        <PerfectGiftFastModalOptions
                            label={blockObj?.title}
                            filterOptionsData={interestData || blockObj?.interests_block?.interests}
                            handleClick={addInterestChip}
                            selectedChips={selectedInterest}
                            handleDelete={removeInterestChip}
                            styled={styled}
                        />
                    )
                );
            default:
                return null;
        }
    };
    return (
        <Dialog
            classes={{
                paper: classes.modalBox,
            }}
            open={open}
            onClose={() => closeModal()}
            className={classes.dialogHeader}
            TransitionComponent={LeftToRightTransition}
            TransitionProps={{
                transitionDirection: 'up',
            }}
        >
            <DialogTitle>
                <div className={classes.closeButtonContainer}>
                    <IconButton
                        className={classes.closeBtn}
                        aria-label="Close"
                        onClick={() => closeModal()}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.dialogTitle}>
                    {blockData?.header?.heading}
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {blockData?.filter_options?.map((item) => renderFields(item))}
            </DialogContent>
            <div className={classes.bottomButtonMainContainer}>
                <div className={classes.bottomButtonContainer}>
                    <strong
                        onClick={() => closeModal()}
                        role="presentation"
                        className={classes.cancelButton}
                    >
                        Cancel
                    </strong>
                    <Button
                        id="suggestions_button"
                        className={classes.suggestionsButton}
                        type="submit"
                        fullWidth
                        disabled={hasZipCodeObject ? !(zipCodeIsValid && zipCodeIsValid !== null && zip.length >= 5) : false}
                        onClick={() => { handleSuggestion(); }}
                    >
                        <strong> {blockData?.header?.cta?.title} </strong>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

PerfectGiftFastModal.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: func.isRequired,
    zip: array.isRequired,
    blockData: object.isRequired,
    isMobile: bool.isRequired,
    setSelectedOccasion: func.isRequired,
    isModalEdit: bool,
    getZipCode: string,
    validatedZip: string,
    closeEditModal: func,
    addedTagging: func.isRequired,
    zipCodeIsValid: bool,
};

PerfectGiftFastModal.defaultProps = {
    isModalEdit: false,
    getZipCode: '',
    validatedZip: '',
    closeEditModal: () => {},
    zipCodeIsValid: false,
};
export default PerfectGiftFastModal;
