/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @description Finds recipientRank data
 * @param { string } emailId unique identifier for a customer
 * @param { string } environment graphql env
 * @param { string } brand
 * @param { RecipientRankingModel } model
 */

const findRecipientRank = () => (
    gql`query findRecipientRank (
        $brand: String!
        $environment: String!
        $emailId: String!
        $model: RecipientRankingModel!
    ) {
        recipientRank(
            brand: $brand
            environment: $environment
            emailId: $emailId
            model: $model
        )  {
            recipientList{
                recipFirstName, 
                recipLastName, 
                frequency, 
                totalPrice,
                latestOrderDate,
                score,
                relationship
            } 
            content
         }
    }`
);

export default findRecipientRank;
