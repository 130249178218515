/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useEffect } from 'react';
import {
    bool, func, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { setProductFilterOccasion, setUserSubmittedProductFilterZipcode } from '../../../../../../../state/ducks/App/App-Actions';
import noop from '../../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'block',
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '14px',
        fontFamily: 'Lato',
        '@media only screen and (max-width: 600.5px) ': {
            fontSize: '18px',
            lineHeight: '32px',
            marginBottom: '8px',
        },
    },
    zipFieldContainer: {
        display: 'flex',
        width: '60%',
        gap: '8px',
        position: 'relative',
        '@media only screen and (max-width: 600.5px) ': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            marginBottom: '0px',
            borderBottom: 'inherit',
        },
        '& .MuiFormControl-root': {
            paddingRight: '0px',
        },
        '& input': {
            padding: '11px',
            fontSize: '16px',
            border: '1px solid #C4C4C4',
            height: 'auto',
            backgroundColor: theme.palette.cms?.white || '#ffffff',
            borderRadius: '3px',
            '&:focus': {
                outline: 0,
            },
        },
        '& .Mui-disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26)',
        },
    },
    mainContainer: {
        marginBottom: '32px',
        '@media only screen and (max-width: 600.5px) ': {
            marginBottom: '20px',
        },
    },

    zipCodeInputValid: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            backgroundColor: theme.palette.colorValid || '#24b200',
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            margin: '-32px 0px 0px 95px',
            fontSize: '16px',
            padding: '5px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
            top: -8,
            left: 70,
            '@media only screen and (max-width: 600.5px) ': {
                top: -4,
                left: 55,
            },
        },
    },

    zipCodeInputInvalidIcon: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            backgroundColor: theme.palette.cms?.errorZipVerifyPDP || '#DF3030',
            color: theme.palette.white,
            textAlign: 'center',
            content: '\'\\2715\'',
            fontSize: '14px',
            padding: '5px',
            fontWeight: 'bold',
            margin: '-32px 0px 0px 96px',
            lineHeight: '16px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
            top: -8,
            left: 70,
            '@media only screen and (max-width: 600.5px) ': {
                top: -4,
                left: 50,
            },
        },
    },
    zipCodeInputInvalid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '99',
            color: theme.palette.cms?.errorZipVerifyPDP || '#DF3030',
            lineHeight: '18px',
            fontSize: '12px',
            fontWeight: '400',
            content: '"Invalid Zip Code"',
            bottom: '-21px',
            left: 0,
            paddingLeft: '0px',
            width: '220px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
        '& input': {
            border: `1px solid ${theme.palette.cms?.errorZipVerifyPDP || '#DF3030'}`,
        },
    },
    zipCodeInput: {
        width: '100%',
    },
    wrapperV2: {
        maxWidth: '370px',
        margin: '12px auto 27px',
        '& $zipFieldContainer': {
            width: '100%',
        },
        '@media only screen and (max-width: 424px) ': {
            maxWidth: '100%',
            padding: '0px 10px',

        },
        '& $zipCodeInputInvalidIcon, $zipCodeInputValid': {
            '&::after': {
                width: '16px',
                height: '16px',
                lineHeight: '6px',
                padding: '4px 0px',
                fontSize: '12px',
                left: 'inherit',
                right: '-15px',
                top: '3px',
                '@media only screen and (max-width: 358px) ': {
                    right: '-32px !important',
                },
            },
        },
        '& $zipCodeInputValid': {
            '&::after': {
                '-webkit-transform': 'rotate(15deg)',
                ' -moz-transform': 'rotate(15deg)',
                '-o-transform': 'rotate(15deg)',
                transform: 'rotate(15deg)',
                right: '96px !important',
                '@media only screen and (max-width: 402px) ': {
                    right: '23% !important',
                },
                '@media only screen and (max-width: 350px) ': {
                    right: '21% !important',
                },
            },
        },
        '& $zipCodeInputInvalidIcon': {
            fontSize: '10px',
        },
        '& $zipCodeInputInvalid': {
            '&::before': {
                textAlign: 'left',
                padding: '2px 0px 0px 0px',
            },
        },
        '& $header': {
            fontSize: '18px',
            lineHeight: '26px',
            marginBottom: '10px',
            fontWeight: '400',

        },
        '@media only screen and (max-width: 600.5px) ': {
            margin: '8px auto 16px',
            '& $header': {
                fontSize: '16px',
                lineHeight: '32px',
                marginBottom: '4px',
            },
            '& $zipCodeInputInvalidIcon, $zipCodeInputValid': {
                '&::after': {
                    top: '6px !important',
                },
            },
        },
    },
    button: {
        cursor: 'pointer',
        margin: '0px',
        display: 'flex',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        alignItems: 'center',
        padding: '11px 0px',
        minWidth: '102px',
        justifyContent: 'center',
        border: 'none',
        borderRadius: '3px',
        background: '#52297E',
        color: '#ffffff',
        '@media only screen and (max-width: 424px) ': {
            minWidth: '80px',
        },
    },
    btnDisabled: {
        background: 'rgba(0, 0, 0, 0.26)',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}));
const PerfectGiftFastModalZipField = ({
    handleChange,
    zip,
    getZipCode,
    setHideZipModal,
    addedTagging,
    zipCodeIsValid,
    label,
    hideZipModal,
    openDialog,
    isModalEdit,
    isZipFirstStep,
}) => {
    const classes = useStyles();
    const textFieldRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (zipCodeIsValid && !hideZipModal) {
            dispatch(setUserSubmittedProductFilterZipcode(zip?.trim()));
            setHideZipModal(true);
            addedTagging(zip, 'Zip');
        } else {
            dispatch(setUserSubmittedProductFilterZipcode(''));
        }
    }, [zipCodeIsValid]);

    const displayZipValidCSSCheck = () => {
        if (zip.length >= 5 && zipCodeIsValid !== null) {
            if (zipCodeIsValid) {
                return `${classes.zipCodeInputValid} ${classes.zipInputTextValid} zip-code-success`;
            }
            return `${classes.zipCodeInputInvalidIcon} ${classes.zipCodeInputInvalid} zip-code-error`;
        }
        return '';
    };
    const isZipCodeValid = zip.length >= 5 && zipCodeIsValid !== null && zipCodeIsValid;
    return (
        <div className={`${classes.mainContainer} ${isZipFirstStep && !isModalEdit ? classes.wrapperV2 : ''}`}>
            <strong className={classes.header}>{label}</strong>
            <div className={classes.zipFieldContainer}>
                <div className={`${classes.zipCodeInput} ${displayZipValidCSSCheck()}`}>
                    <TextField
                        fullWidth
                        placeholder="Enter a ZIP code"
                        defaultValue={getZipCode}
                        className={classes.paddingRight}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        onChange={handleChange('zip')}
                        value={zip}
                        inputRef={textFieldRef}
                    />
                </div>
                {isZipFirstStep && !isModalEdit && (
                    <button
                        type="button"
                        aria-label="close"
                        className={`${classes.button} ${!isZipCodeValid ? classes.btnDisabled : ''}`}
                        disabled={!isZipCodeValid}
                        onClick={() => {
                            dispatch(setProductFilterOccasion('', '', ''));
                            openDialog(true);
                        }}
                    >
                        GO
                    </button>
                )}
            </div>
        </div>
    );
};

PerfectGiftFastModalZipField.propTypes = {
    zip: string.isRequired,
    handleChange: func.isRequired,
    zipCodeIsValid: bool.isRequired,
    getZipCode: string.isRequired,
    hideZipModal: bool.isRequired,
    setHideZipModal: func.isRequired,
    addedTagging: func.isRequired,
    label: string.isRequired,
    openDialog: func,
    isModalEdit: bool,
    isZipFirstStep: bool,
};
PerfectGiftFastModalZipField.defaultProps = {
    openDialog: noop,
    isModalEdit: false,
    isZipFirstStep: false,
};

export default PerfectGiftFastModalZipField;
